import styled from 'styled-components';

interface IStyledColumns {
  justify: any;
  display: any;
}

export const StyledColumns = styled.div<IStyledColumns>`
  display: ${({ display }) => display || `flex`};
  justify-content: ${({ justify }) => justify || `flex-start`};
`;

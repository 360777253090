import styled from 'styled-components';
import { rem } from 'polished';

export const StyledGrid = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: dense;
  grid-gap: ${rem(`24px`)};
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) => theme.breakpoints.phone`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'gatsby';
import { StyledTableRow } from './styles';
import { Text } from '../Text';
import { MdContent } from '../MdContent';

export interface ITableRow {
  category: string;
  content?: string;
  className?: string;
  linkTo?: string;
  children?: any;
}

export const TableRow = ({
  category,
  content,
  className,
  linkTo,
  children,
}: ITableRow) => {
  return (
    <StyledTableRow className={className}>
      <div className="table-row__category">
        <Text noRhythem type="body1" tag="span" color="white_1_60">
          {category}
        </Text>
      </div>
      <div className="table-row__content">
        {linkTo ? (
          <Text noRhythem type="body1" tag="section" color="gray_3">
            <Link to={linkTo} className="table-row__link">
              <MdContent content={content} />
            </Link>
          </Text>
        ) : (
          children || (
            <Text noRhythem type="body1" tag="section" color="gray_3">
              <MdContent content={content} />
            </Text>
          )
        )}
      </div>
    </StyledTableRow>
  );
};

import React from 'react';
import { StyledTeamHeader } from './styles';
import { Text } from '../Text';
import { Avatar } from '../Avatar';

interface ITeamHeader {
  className?: string;
  image: any;
  alt: string;
  title: string;
}

export const TeamHeader = ({ className, image, alt, title }: ITeamHeader) => (
  <StyledTeamHeader className={className}>
    <div className="team-header__image">
      <Avatar disableLink linkTo="/about/" imageUrl={image} alt={alt} />
    </div>
    <div className="team-header__title">
      <Text type="title1" tag="h2" color="white_1">
        {title}
      </Text>
    </div>
  </StyledTeamHeader>
);

import React from 'react';
import { StyledRows } from './styles';

interface IRows {
  className?: string;
  style?: any;
  children?: any;
  justify?: string;
  display?: string;
}

export const Rows = ({
  className,
  style,
  children,
  justify,
  display,
}: IRows) => (
  <StyledRows
    className={className}
    style={style}
    justify={justify}
    display={display}
  >
    {children}
  </StyledRows>
);

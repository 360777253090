import styled from 'styled-components';
import { rem } from 'polished';

export const StyledTableRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${rem(`8px`)};
  flex-wrap: wrap;
  ${({ theme }) => theme.breakpoints.phablet`
    flex-wrap: nowrap;
  `}
  .table-row__category {
    flex: 1 0 100%;
    margin-bottom: ${rem(`4px`)};
    ${({ theme }) => theme.breakpoints.phablet`
      flex: 1 0 200px;
      margin-bottom: 0;
    `}
    ${({ theme }) => theme.breakpoints.wide`
      flex: 1 0 250px;
    `}
  }
  .table-row__content {
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    ${({ theme }) => theme.breakpoints.phablet`
      width: calc(100% - 200px);
    `}
    ${({ theme }) => theme.breakpoints.wide`
      width: calc(100% - 250px);
    `}
  }
  .table-row__link {
    section {
      color: ${({ theme }) => theme.colors.pink_1};
      ${({ theme }) => theme.breakpoints.tablet`
        &:hover {
          color: ${theme.colors.pink_2};
        }
      `}
    }
  }
`;

import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledListItem {
  contrast: any;
}

export const StyledListItem = styled.div<IStyledListItem>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${({ theme, contrast }) =>
    contrast ? theme.colors.blue_1_65 : theme.colors.white_1};
  margin-bottom: ${rem(`3px`)};
  ${({ theme }) => theme.breakpoints.tablet`
    margin-bottom: ${rem(`6px`)};
  `}
  &.last-item {
    margin-bottom: ${rem(`12px`)};
  }
  .listItem__icon {
    margin: ${rem(`3px`)} ${rem(`8px`)} 0 0;
    color: ${({ theme }) => theme.colors.pink_1};
    ${({ theme }) => theme.breakpoints.phablet`
      margin: ${rem(`3px`)} ${rem(`8px`)} 0 0;
    `}
    ${({ theme }) => theme.breakpoints.tablet`
      margin: ${rem(`5px`)} ${rem(`8px`)} 0 0;
    `}
  }
  .listItem__text {
    display: flex;
  }
`;

import styled from 'styled-components';

interface IStyledRows {
  justify: any;
  display: any;
}

export const StyledRows = styled.div<IStyledRows>`
  display: ${({ display }) => display || `flex`};
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify || `flex-start`};
  > * {
    flex: 1 0 100%;
  }
`;

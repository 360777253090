import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/pro-solid-svg-icons';
import { StyledListItem } from './styles';
import { Text } from '../Text/index';

interface IListItem {
  contrast?: boolean;
  children: any;
  className?: string;
}

export const ListItem = ({ contrast, children, className }: IListItem) => (
  <StyledListItem contrast={contrast} className={className}>
    <FontAwesomeIcon className="listItem__icon" icon={faDotCircle} />
    <Text className="listItem__text" noRhythem type="body3" tag="span">
      {children}
    </Text>
  </StyledListItem>
);

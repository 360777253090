import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { getImage } from 'gatsby-plugin-image';
import { CallToAction } from '@/components/CallToAction';
import { Rows } from '@/components/Rows';
import { Columns } from '@/components/Columns';
import Layout, { Main } from '../../components/Layout';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { TeamHeader } from '../../components/TeamHeader';
import { TableRow } from '../../components/TableRow';
import { Grid } from '../../components/Grid';
import { Seo } from '../../components/SEO';
import { MdContent } from '../../components/MdContent';

interface ITeamTemplate {
  content?: any;
}

interface ITeam {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulPerson: any;
  };
  location: object;
}

const StyledHeader = styled.div`
  margin-bottom: ${rem(`63px`)};
`;

const TeamTemplate = ({ content }: ITeamTemplate) => {
  const {
    name,
    role,
    image: teamMemberImage,
    age,
    geoLocation,
    education,
    experience,
    social,
    languages,
    strengths,
    projects,
    stack,
  } = content;

  const image = getImage(teamMemberImage);
  return (
    <>
      <Content restrictWidth style={{ marginBottom: `48px` }} setMaxWidth="75%">
        <TeamHeader title={name} image={image} alt={`Image of ${name}`} />
        <Text type="subtitle1" tag="h3" color="white_1_60">
          Resume and career path
        </Text>
        <Text type="subtitle1" tag="h3" color="yellow_1">
          General
        </Text>
        <TableRow category="Role">
          <Text noRhythem type="body1" tag="section" color="gray_3">
            {role}
          </Text>
        </TableRow>
        <TableRow category="Age">
          <Text noRhythem type="body1" tag="section" color="gray_3">
            {age}
          </Text>
        </TableRow>
        <TableRow category="Location">
          <Text noRhythem type="body1" tag="section" color="gray_3">
            {geoLocation}
          </Text>
        </TableRow>
        <TableRow category="Education">
          <Text noRhythem type="body1" tag="section" color="gray_3">
            {education}
          </Text>
        </TableRow>
        <TableRow category="Social" content={social.social} />
        <Text type="subtitle1" tag="h3" color="yellow_1">
          Expirience
        </Text>
        {experience.map(({ category, content: expirienceContent }) => (
          <TableRow
            key={uuidv4()}
            category={category}
            content={expirienceContent}
          />
        ))}
        <Text type="subtitle1" tag="h3" color="yellow_1">
          Projects
        </Text>
        <Text type="body1" tag="section" color="gray_3">
          <MdContent content={projects.projects} />
        </Text>
        <Text type="subtitle1" tag="h3" color="yellow_1">
          Languages
        </Text>
        {languages.map(({ category, content: languagesContent }) => (
          <TableRow
            key={uuidv4()}
            category={category}
            content={languagesContent}
          />
        ))}
        <Text type="subtitle1" tag="h3" color="yellow_1">
          Strengths
        </Text>
        <Text type="body1" tag="section" color="gray_3">
          <MdContent content={strengths.strengths} />
        </Text>
        <Text type="subtitle1" tag="h3" color="yellow_1">
          Technological stack
        </Text>
        <Grid data={stack} />
      </Content>

      <Content restrictWidth shouldCenter className="content" setMaxWidth="75%">
        <Link to="/about/">
          <Button size="large" disabled={false} style={{ marginTop: `48px` }}>
            Back to About
          </Button>
        </Link>
      </Content>
    </>
  );
};

const TeamDesktopTemplate = ({ content }: ITeamTemplate) => {
  const { languages, strengths, projects, stack } = content;
  return (
    <Rows>
      <Columns>
        <Content>
          <Text type="subtitle1" tag="h3" color="yellow_1">
            Projects
          </Text>
          <Text type="body1" tag="section" color="gray_3">
            <MdContent content={projects.projects} />
          </Text>
          <Text type="subtitle1" tag="h3" color="yellow_1">
            Languages
          </Text>
          {languages.map(({ category, content: languagesContent }) => (
            <TableRow
              key={uuidv4()}
              category={category}
              content={languagesContent}
            />
          ))}
          <Text type="subtitle1" tag="h3" color="yellow_1">
            Strengths
          </Text>
          <Text type="body1" tag="section" color="gray_3">
            <MdContent content={strengths.strengths} />
          </Text>
        </Content>
        <Content>
          <Text type="subtitle1" tag="h3" color="yellow_1">
            Technological stack
          </Text>
          <Grid data={stack} />
        </Content>
      </Columns>

      <Content restrictWidth shouldCenter noMargin>
        <Link to="/about/">
          <Button size="large" disabled={false} style={{ marginTop: `48px` }}>
            Back to About
          </Button>
        </Link>
      </Content>
    </Rows>
  );
};

const TeamPage = ({ data, location }: ITeam) => {
  const rootPage = get(data, `contentfulPage`);
  const person = get(data, `contentfulPerson`);
  const {
    heroImage,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = rootPage;
  const {
    slug,
    metaDescription,
    metaTitle,
    image,
    svgTopPath,
    svgBottomPath,
    name,
    role,
    age,
    geoLocation,
    templateKey,
    education,
    social,
    components,
    strengths,
    projects,
  } = person;
  const { experience, languages, stack } = components;
  const hero = getImage(heroImage);
  const member = getImage(image);
  const ctaIcon = getImage(pageCTAIcon);
  return (
    <Layout
      location={location}
      coverImage={hero}
      title={name}
      topSvg={svgTopPath.svg.content}
      bottomSvg={svgBottomPath.svg.content}
      variant={3}
      desktopHeading={
        <Content restrictWidth setMaxWidth="90%" noMargin>
          <StyledHeader>
            <TeamHeader title={name} image={member} alt={`Image of ${name}`} />
          </StyledHeader>
          <Text type="subtitle1" tag="h3" color="white_1_60">
            Resume and career path
          </Text>
          <Text type="subtitle1" tag="h3" color="yellow_1">
            General
          </Text>
          <TableRow category="Role">
            <Text noRhythem type="body1" tag="section" color="gray_3">
              {role}
            </Text>
          </TableRow>
          <TableRow category="Age">
            <Text noRhythem type="body1" tag="section" color="gray_3">
              {age}
            </Text>
          </TableRow>
          <TableRow category="Location">
            <Text noRhythem type="body1" tag="section" color="gray_3">
              {geoLocation}
            </Text>
          </TableRow>
          <TableRow category="Education">
            <Text noRhythem type="body1" tag="section" color="gray_3">
              {education}
            </Text>
          </TableRow>
          <TableRow category="Social" content={social.social} />
          <Text type="subtitle1" tag="h3" color="yellow_1">
            Experience
          </Text>
          {experience.map(({ category, content: experienceContent }) => (
            <TableRow
              key={uuidv4()}
              category={category}
              content={experienceContent}
            />
          ))}
        </Content>
      }
      desktopBody={
        <TeamDesktopTemplate
          content={{
            languages,
            strengths,
            projects,
            stack,
          }}
        />
      }
      desktopCta={
        <Content>
          <CallToAction
            hookId={ctaHookId}
            icon={ctaIcon}
            title={ctaTitle}
            body={ctaBody.ctaBody}
            maxWidth="100%"
          />
        </Content>
      }
    >
      <Seo
        title={name}
        metaTitle={metaTitle}
        metaDesc={metaDescription}
        cover={hero}
        slug={slug}
        templateKey={templateKey}
      />
      <Main background="transparent" disableVertPadding={false}>
        <TeamTemplate
          content={{
            image,
            name,
            role,
            age,
            geoLocation,
            education,
            experience,
            social,
            languages,
            strengths,
            projects,
            stack,
          }}
        />
      </Main>
    </Layout>
  );
};

export default TeamPage;

export const pageQuery = graphql`
  query PersonPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(contentful_id: { eq: "6lbdJbkgafXqctWcWT4N5L" }) {
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    contentfulPerson(slug: { eq: $slug }) {
      image {
        gatsbyImageData(
          width: 72
          height: 72
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      templateKey
      name
      role
      slug
      metaTitle
      metaDescription
      age
      geoLocation
      education
      strengths {
        strengths
      }
      social {
        social
      }
      projects {
        projects
      }
      components {
        experience {
          category
          content
        }
        languages {
          category
          content
        }
        stack {
          title
          techs {
            name
            link
          }
        }
      }
    }
  }
`;

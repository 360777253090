import styled from 'styled-components';
import { rem } from 'polished';

export const StyledTeamHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  .team-header__image {
    flex: 0 1;
    margin-right: ${rem(`16px`)};
    ${({ theme }) => theme.breakpoints.phablet`
      margin-right: ${rem(`32px`)};
    `}
  }
  .team-header__title {
    flex: 1 0;
  }
`;
